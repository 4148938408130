<template>
  <CRow>
    <CCol col>
      <CCard>
        <CCardHeader> <h5>Categories</h5> </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            striped
            border
            :items="categories"
            :fields="fields"
            :items-per-page="10"
            clickable-rows
            :active-page="activePage"
            @row-clicked="rowClicked"
            :pagination="{ doubleArrows: false, align: 'center' }"
            @page-change="pageChange"
          >
            <td slot="subcategories" slot-scope="{ item }">
              <template v-for="subcategory in item.subcategories">
                <CBadge :color="`secondary`" :key="subcategory.id" class="mr-1">
                  {{ subcategory.name }}
                </CBadge>
              </template>
            </td>
            <td slot="status" slot-scope="{ item }">
              <CBadge :color="getBadge(item.status)">
                {{ item.status }}
              </CBadge>
            </td>
            <td slot="created_at" slot-scope="{ item }">
              {{ item.created_at | moment(datetimeFormat) }}
            </td>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACTION_FETCH_CATEGORIES } from '@/store/actions.type';
import { CategoryBadge } from '@/utils/utils';
import { Common } from '@/constants';

export default {
  name: 'Categories',
  data() {
    return {
      datetimeFormat: Common.datetimeFormat,
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Name', _classes: 'font-weight-bold' },
        { key: 'subcategories', label: 'Subcategories' },
        { key: 'status' },
        { key: 'created_at', label: 'Created Date' },
      ],
      activePage: 1,
    };
  },
  mounted() {
    this.$store.dispatch(ACTION_FETCH_CATEGORIES);
  },
  computed: {
    ...mapGetters(['categories']),
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    getBadge(status) {
      return CategoryBadge(status);
    },
    rowClicked(item) {
      this.$router.push({ path: `categories/${item.id}` });
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
};
</script>
